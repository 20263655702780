import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as moment from 'moment';
import {getCurrentDate, getToken, dataSD, getSDMembers, getAgentStates,getAgentConversations} from '../Functions.js';


class WallboardSD extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
      SDMembers: '',
      dataSD: '',
      agents: '',
      agentsACD: '',
      agentsIdle: '',
      agentsNonACD: '',
      agentsUnavailable: ''
    }
  }

  getTimeInQueue(datetimeQueue){

    var date_in_queue = moment(datetimeQueue);
    var date_now = moment();

    return moment.utc(moment(date_now,"DD/MM/YYYY HH:mm:ss").diff(moment(date_in_queue,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
  }

  componentDidMount(){

    var members = [];

    setInterval(() => {
      var currentDate = getCurrentDate();

      var Hour = new Date().getHours();
      var Minutes = new Date().getMinutes();
      var Seconds = new Date().getSeconds();

      console.log(Minutes);

      if(Minutes === 59 && Seconds === 59){
          sessionStorage.removeItem('token');
          window.location.reload();
      }

      this.setState({
        currentDate: currentDate,
      })
    }, 1000)

    if(!sessionStorage.getItem('token') || (sessionStorage.getItem('token') && sessionStorage.getItem('token') === '')){
      getToken();
    }

    setInterval(() => {
      var getDataSD = dataSD();
      var $this = this;

      getSDMembers(function(id, data){
        data.forEach(function(member){
          if(!members.includes(member.name)){
            members.push(member.name);
          }
        })
      })

      getAgentStates(function(id, data){
        var agentsACD = [];
        var agentsIdle = [];
        var agentsUnavailable = [];
        var agentsNonACD = [];
        if(data){
          data.forEach(function(item){

            var agent = item.firstName + ' ' + item.lastName;

            var TimeInQueue = $this.getTimeInQueue(item.enteredStateOn);
            item.timeInQueue = TimeInQueue;

            if(members.includes(agent)){
              if(item.availableState === 'ACD' || item.availableState === 'Acd' || item.availableState === 'acd'){
                getAgentConversations().then(async data => {
                  data.forEach(function(agent){
                    if(agent.agentFirstName === item.firstName && agent.agentLastName === item.lastName){
                      item.queueName = agent.queueName ? agent.queueName : '';
                      sessionStorage.setItem('conv-' + item.firstName + '-' + item.lastName, agent.queueName);
                    }
                  }) 
                })

                agentsACD.push(item);
              }
              else if(item.availableState === 'Idle'){
                agentsIdle.push(item);
              }
              else if(item.availableState === 'NotAvailable'){
                agentsUnavailable.push(item);
              }
              else if(item.availableState === 'NonACD'){
                agentsNonACD.push(item);
              }
            }
          })
          $this.setState({
            agentsACD: agentsACD,
            agentsIdle: agentsIdle,
            agentsUnavailable: agentsUnavailable,
            agentsNonACD: agentsNonACD
          })
        }
    });

      this.setState({
        dataSD: getDataSD
      })
    }, 5000)
  }

  render(){

    var SDData = this.state.dataSD[0];

    var waitingQueueMax = '';

    if(SDData){
      var getHours = SDData.longestWaitingConversationDuration.split(":")[0];
      var getMinutes = SDData.longestWaitingConversationDuration.split(":")[1];
      var getSeconds = SDData.longestWaitingConversationDuration.split(":")[2];
      waitingQueueMax = getHours + ':' + getMinutes + ':' + getSeconds.toString()[0] + '' + getSeconds.toString()[1];
    }

    if(SDData){
      sessionStorage.setItem('servicelevel', SDData.serviceLevelPercentageToday);
      sessionStorage.setItem('waitingqueue', SDData.waitingConversations);
      sessionStorage.setItem('requeued', SDData.requeuedConversationsToday);
      sessionStorage.setItem('longestwaiting', waitingQueueMax);
      sessionStorage.setItem('offered', SDData.offeredConversationsToday);
      sessionStorage.setItem('handled', SDData.answeredConversationsToday);
      sessionStorage.setItem('abandoned', SDData.abandonedConversationsToday);
    }

    return (
      <div className="App">
        <header className="wallboard-header">
          <div className="datetime-today">{this.state.currentDate}</div>
          <img src='/sltn-logo.png' className="logo" alt="logo" />
          <div className="department">Servicedesk</div>
        </header>
  
        <div className="container-fluid">
          <div className="row">
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.serviceLevelPercentageToday : sessionStorage.getItem('servicelevel')}</span><span className="label">Service Level</span></div>
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.waitingConversations : sessionStorage.getItem('waitingqueue')}</span><span className="label">Contacts waiting</span></div>  
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.requeuedConversationsToday : sessionStorage.getItem('requeued')}</span><span className="label">Requeued</span></div>
            <div className="col wallboard-block"><span className="content">{waitingQueueMax ? waitingQueueMax : sessionStorage.getItem('longestwaiting')}</span><span className="label">Longest waiting</span></div>
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.offeredConversationsToday : sessionStorage.getItem('offered')}</span><span className="label">Offered</span></div>
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.answeredConversationsToday : sessionStorage.getItem('handled')}</span><span className="label">Handled</span></div>
            <div className="col wallboard-block"><span className="content">{SDData ? SDData.abandonedConversationsToday : sessionStorage.getItem('abandoned')}</span><span className="label">Abandoned</span></div>
          </div>
          <div className="row agents">
            <div className="col-md-3">
              <span className="listname">ACD</span>
              <ul>
              {this.state.agentsACD ? this.state.agentsACD.map((agent, i) => {
                if(agent.reason !== ''){
                  var reason = agent.reason;
                }else{
                  reason = agent.currentState
                }

                return(
                  <li className="agent">
                    <div className="icon"><i style={{color: '#ED1C24'}} className="fas fa-headset"></i></div>
                    <div className="data">
                      <div className="agent-name">{agent.firstName + ' ' + agent.lastName} ({agent.reporting})</div>
                      <div className="agent-timeinqueue">{agent.timeInQueue}</div>
                      {/* <div className="agent-reporting">{agent.reporting}</div> */}
                      <div className="agent-reason">{reason}</div>
                      <div className="agent-conversation">{agent.queueName ? agent.queueName : sessionStorage.getItem('conv-' + agent.firstName + '-' + agent.lastName)}</div>
                    </div>
                  </li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Idle</span>
              <ul>
              {this.state.agentsIdle ? this.state.agentsIdle.map((agent, i) => {
                if(agent.reason !== ''){
                  var reason = agent.reason;
                }else{
                  reason = agent.currentState
                }

                return(
                  <li className="agent">
                    <div className="icon"><i style={{color: '#3FAC49'}} className="fas fa-headset"></i></div>
                    <div className="data">
                      <div className="agent-name">{agent.firstName + ' ' + agent.lastName} ({agent.reporting})</div>
                      <div className="agent-timeinqueue">{agent.timeInQueue}</div>
                      {/* <div className="agent-reporting">{agent.reporting}</div> */}
                      <div className="agent-reason">{reason}</div>
                    </div>
                  </li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Non ACD</span>
              <ul>
              {this.state.agentsNonACD ? this.state.agentsNonACD.map((agent, i) => {
                if(agent.reason !== ''){
                  var reason = agent.reason;
                }else{
                  reason = agent.currentState
                }

                return(
                  <li className="agent">
                    <div className="icon"><i style={{color: '#0081B3'}} className="fas fa-headset"></i></div>
                    <div className="data">
                      <div className="agent-name">{agent.firstName + ' ' + agent.lastName} ({agent.reporting})</div>
                      <div className="agent-timeinqueue">{agent.timeInQueue}</div>
                      {/* <div className="agent-reporting">{agent.reporting}</div> */}
                      <div className="agent-reason">{reason}</div>
                    </div>
                  </li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Unavailable</span>
              <ul>
              {this.state.agentsUnavailable ? this.state.agentsUnavailable.map((agent, i) => {
                if(agent.reason !== ''){
                  var reason = agent.reason;
                }else{
                  reason = agent.currentState
                }

                return(
                  <li className="agent">
                    <div className="icon"><i style={{color: '#F8CA00'}} className="fas fa-headset"></i></div>
                    <div className="data">
                      <div className="agent-name">{agent.firstName + ' ' + agent.lastName} ({agent.reporting})</div>
                      <div className="agent-timeinqueue">{agent.timeInQueue}</div>
                      {/* <div className="agent-reporting">{agent.reporting}</div> */}
                      <div className="agent-reason">{reason}</div>
                    </div>
                  </li>
                );
              }) : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WallboardSD;
